<template>
  <div class="row" id="client-user">
    <div class="col-md-8 offset-2">
      <div class="card form-body">
        <div class="card-body">
          <div class="d-flex">
            <legend><h6>User Information</h6></legend>
            <div class="form-group">
              <span class="switch">
                <input
                  type="checkbox"
                  v-model="is_active"
                  class="switch"
                  id="switch-normal_user_new"
                />
                <label for="switch-normal_user_new"></label>
              </span>
            </div>
          </div>

          <hr />

          <div class="row">
            <div class="col">
              <div class="form-group">
                <label for=""
                  >First Name <span class="text-danger">*</span></label
                >
                <input
                  type="text"
                  name=""
                  class="form-control"
                  id=""
                  v-model="first_name"
                  :class="errors['first_name'] ? 'border border-danger' : ''"
                />
                <span v-if="errors['first_name']" class="text-danger">{{
                  errors["first_name"][0]
                }}</span>
              </div>
            </div>
            <div class="col">
              <div class="form-group">
                <label for=""
                  >Last Name <span class="text-danger">*</span></label
                >
                <input
                  type="text"
                  Last
                  Name=""
                  class="form-control"
                  id=""
                  v-model="last_name"
                  :class="errors['last_name'] ? 'border border-danger' : ''"
                />
                <span v-if="errors['last_name']" class="text-danger">{{
                  errors["last_name"][0]
                }}</span>
              </div>
            </div>
          </div>
          <div class="form-group" v-if="currentUser.u.is_admin == 0">
            <label for="user_email"
              >Email <span class="text-danger">*</span></label
            >
            <input
              type="email"
              name=""
              class="form-control"
              id="user_email"
              v-model="email"
              :class="errors['username'] ? 'border border-danger' : ''"
            />
            <span v-if="errors['username']" class="text-danger">{{
              errors["username"][0]
            }}</span>
          </div>
          <div class="row" v-if="currentUser.u.is_admin == 1">
            <div class="col">
              <div class="form-group">
                <label for="user_email"
                  >Email <span class="text-danger">*</span></label
                >
                <input
                  type="email"
                  name=""
                  class="form-control"
                  id="user_email"
                  v-model="email"
                  :class="errors['email'] ? 'border border-danger' : ''"
                />
                <span v-if="errors['email']" class="text-danger">{{
                  errors["email"][0]
                }}</span>
              </div>
            </div>
            <div class="col">
              <div class="form-group">
                <label for="user_email"
                  >Password <span class="text-danger">*</span></label
                >
                <input
                  type="password"
                  name=""
                  class="form-control"
                  id="user_password"
                  v-model="password"
                  :class="errors['password'] ? 'border border-danger' : ''"
                />
                <span v-if="errors['password']" class="text-danger">{{
                  errors["password"][0]
                }}</span>
              </div>
            </div>
          </div>
          <div class="form-group">
            <label for="exampleFormControlSelect1">Select role</label>
            <select
              class="form-control"
              id="exampleFormControlSelect1"
              v-model="roles"
            >
              <option value="admin">Admin</option>
              <option value="staff" selected>Staff</option>
            </select>
          </div>

          <!-- <div class="form-group">
            <label for="">Username <span class="text-danger">*</span></label>
            <input
              type="text"
              name=""
              class="form-control"
              id=""
              v-model="username"
              :class="errors['username'] ? 'border border-danger' : ''"
            />
            <span v-if="errors['username']" class="text-danger">{{
              errors["username"][0]
            }}</span>
          </div> -->
        </div>
        <div class="card-footer">
          <button
            v-if="isEdit"
            class="btn btn-success"
            @click="update('kt_update_service')"
            style="float: right"
            ref="kt_update_service"
          >
            <i class="fa fa-check"></i>Update
          </button>
          <button
            v-if="!isEdit"
            class="btn btn-success ml-3"
            style="float: right"
            @click="store('S', 'kt_save_service')"
            ref="kt_save_service"
          >
            <i class="fa fa-check"></i>Save
          </button>
          <button
            v-if="!isEdit"
            class="btn btn-success"
            style="float: right"
            @click="store('SAA', 'kt_save_and_add_service')"
            ref="kt_save_and_add_service"
          >
            <i class="fa fa-check"></i>Save and Add Another
          </button>

          <button
            ref="kt_save_add_another_company"
            class="btn btn-danger mr-3"
            @click="goBack()"
            style="float: right"
          >
            <i class="fa fa-arrow-left"></i> Back
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/mutations.type";
import { mapGetters } from "vuex";
import {
  STORE_B_USER,
  GET_B_USER_DATA,
  UPDATE_B_USER,
  STORE_ADMIN_USER
} from "@/core/services/store/actions.type";
export default {
  data() {
    return {
      isEdit: false,
      breadCrumbs: [
        {
          id: 1,
          title: "Dashboard",
          route: "client.dashboard"
        },
        {
          id: 2,
          title: "Users",
          route: "client.user"
        },
        {
          id: 3,
          title: "Create New User",
          route: ""
        }
      ],
      errors: [],

      //form-data
      username: "",
      first_name: "",
      last_name: "",
      email: "",
      is_active: true,
      password: "",
      roles: "none"
    };
  },
  computed: {
    ...mapGetters(["currentUser"])
  },
  created() {
    if (this.$route.path == "/admin/user/create") {
      this.breadCrumbs[1].route = "admin.user";
      this.breadCrumbs[0].route = "admin.dashboard";
    } else {
      this.isEdit = this.$route.name == "client.user.edit" ? true : false;
      var id = this.$route.params.id;
    }
    this.$store.commit(SET_BREADCRUMB, this.breadCrumbs);

    if (this.isEdit) {
      this.breadCrumbs[2].title = "";
      this.$store.dispatch(GET_B_USER_DATA, id).then(data => {
        this.breadCrumbs[2].title = "Edit | " + data.username;
        this.email = data.email;
        this.first_name = data.first_name;
        this.last_name = data.last_name;
        this.username = data.username;
        this.is_active = data.is_active;
        this.roles - data.roles;
      });
    }
  },
  methods: {
    goBack() {
      if (this.$route.path == "/admin/user/create") {
        this.$router.push({
          name: "admin.user"
        });
      } else {
        var client_slug = this.$route.params.client_slug;
        this.$router.push({
          name: "client.user",
          params: { client_slug: client_slug }
        });
      }
    },

    clearForm() {
      //form-data
      this.username = "";
      this.first_name = "";
      this.last_name = "";
      this.email = "";
      this.is_active = false;
      this.password = "";
      this.roles = "roles";
    },
    closeBtnLoad(ref) {
      const submitButton = this.$refs[ref];
      submitButton.classList.remove(
        "spinner",
        "spinner-light",
        "spinner-right"
      );
      submitButton.disabled = false;
    },
    loadingButton(ref) {
      const submitButton = this.$refs[ref];
      submitButton.disabled = true;
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");
    },
    //saveType = S =  Save Only and go back to list where SAA =  save and add another or stay in same page
    store(saveType, ref) {
      this.errors = [];
      var client_slug = this.currentUser.c.slug;

      if (this.$route.path != "/admin/user/create") {
        client_slug = this.$route.params.client_slug;
      }
      this.loadingButton(ref);
      var _data = {
        username: this.email,
        name: this.first_name + " " + this.last_name,
        first_name: this.first_name,
        last_name: this.last_name,
        email: this.email,
        client_slug: client_slug,
        is_active: this.is_active,
        is_client: this.currentUser.u.is_client,
        is_service_provider: this.currentUser.u.is_service_provider,
        roles: this.roles
      };
      // _data.client_slug = client_slug;
      // console.log(this.errors);
      if (this.currentUser.u.is_admin == 0) {
        this.$store
          .dispatch(STORE_B_USER, _data)
          .then(data => {
            this.$toastr.s(data.msg);
            this.closeBtnLoad(ref);
            //saveType = S =  Save Only and go back to list where SAA =  save and add another or stay in same page
            if (saveType == "S") {
              if (this.$route.path == "/admin/user/create") {
                this.$router.push({
                  name: "admin.user"
                });
              } else {
                this.$router.push({
                  name: "client.user",
                  params: { client_slug: client_slug }
                });
              }
            } else if (saveType == "SAA") {
              this.clearForm();
            }
          })
          .catch(err => {
            this.errors = err;
            this.closeBtnLoad(ref);
          });
      } else {
        _data.password = this.password;
        this.$store
          .dispatch(STORE_ADMIN_USER, _data)
          .then(data => {
            this.$toastr.s(data.msg);
            this.closeBtnLoad(ref);
            //saveType = S =  Save Only and go back to list where SAA =  save and add another or stay in same page
            if (saveType == "S") {
              if (this.$route.path == "/admin/user/create") {
                this.$router.push({
                  name: "admin.user"
                });
              } else {
                var client_slug = this.$route.params.client_slug;
                this.$router.push({
                  name: "client.user",
                  params: { client_slug: client_slug }
                });
              }
            } else if (saveType == "SAA") {
              this.clearForm();
            }
          })
          .catch(err => {
            this.errors = err;
            this.closeBtnLoad(ref);
          });
      }
    },
    update(ref) {
      this.errors = [];
      this.loadingButton(ref);
      var _data = {
        username: this.username,
        name: this.first_name + " " + this.last_name,
        first_name: this.first_name,
        last_name: this.last_name,
        email: this.email,
        password: this.password,
        is_client: this.currentUser.u.is_client,
        is_service_provider: this.currentUser.u.is_service_provider,
        is_active: this.is_active
      };

      this.$store
        .dispatch(UPDATE_B_USER, {
          id: this.$route.params.id,
          data: _data
        })
        .then(data => {
          this.$toastr.s(data.msg);
          this.closeBtnLoad(ref);
          if (this.$route.path == "/admin/user/create") {
            this.$router.push({
              name: "admin.user"
            });
          } else {
            var client_slug = this.$route.params.client_slug;
            this.$router.push({
              name: "client.user",
              params: { client_slug: client_slug }
            });
          }
        })
        .catch(err => {
          this.errors = err;
          this.closeBtnLoad(ref);
        });
    }
  }
};
</script>

<style></style>
